
import {ANNOTATION_VIDEO_DISPLAY,ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';

const ANNOTATION_CONTACT_TEXT = 'kilometro0.2pr@gmail.com';

const AnnotationsDB = [

    {
        paintingId: 'W01V01',
        videoId: 'W04V01',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W01V01.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: '',
        displayMesh:'N', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'displayVideo',
    },

    {
        paintingId: 'W01V01A',
        videoId: 'W01V01A',
        videoURL:'W01V01.mp4',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        headerText: '<h5>Ana Izquierdo</h5><h6>Ejercicio de Resistencia 1</h6>',
        emailSubjectText: `Ana Izquierdo Ejercicio de Resistencia 1`,
        bodyText: `Video <br> 19:20min <br> 2018-2019 <br> 1/3 + 2 P/A <br> $2700USD`,
        imageURL:`/paintings/W01V01.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        annotationScale: {x:.05,y:.05,z:.05},
        annotationPos:{x:-.225,y:-.15,z:-6},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W02S01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Raura Oblitas</h5><h6>S/T 4 (serie LOTE)</h6>',
        emailSubjectText: `Raura Oblitas S/T 4 (serie LOTE)`,
        bodyText: `Mármol blanco, broches de bronce, <br> metal soldado ymalla metálica <br> 2019 <br> 55” x 81” x 7 ” $12000 USD`,
        imageURL:`/paintings/W02S01.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        isInSitu: 'Y',
        images: ['W02S01C1.jpg', 'W02S01C2.jpg'],
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        annotationScale: {x:.05,y:.05,z:.05},
        annotationPos:{x:2.29,y:1.054,z:-1.822},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W03C01',
        videoId: null,
        videoURL: null,
        hasCarouselAsParent: 'Y',
        carouselSiblings: ['W03P01','W03P02', 'W03P03'],
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        annotationScale: {x:.05,y:.05,z:.05},
        annotationPos:{x:-1.2,y:0.6,z:-.156},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W03P01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Corbett Fogue</h5><h6>Breath Study 4</h6>',
        emailSubjectText: `Corbett Fogue Breath Study 4`,
        bodyText: `Last Words; January 11, 2012 <br> Silver Gelatin Photogram <br> Lambda C-type Print <br> 8x10” <br> 1/1 (1st edition - 10/10 available) <br> $500USD`,
        imageURL:`/paintings/W03P01.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        hasCarouselAsParent: 'Y',
        carouselSiblings: ['W03P02', 'W03P03'],
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W03P02',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Corbett Fogue</h5><h6>Breath Study 5</h6>',
        emailSubjectText: `Corbett Fogue Breath Study 5`,
        bodyText: `Last Words; January 11, 2012 <br>Silver Gelatin Photogram <br> Lambda C-type Print <br> 8x10” <br> 1/1 (1st edition - 10/10 available) <br> $500USD`,
        imageURL:`/paintings/W03P02.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W03P03',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Corbett Fogue</h5><h6>Breath Study 6</h6>',
        emailSubjectText: `Corbett Fogue Breath Study 6`,
        bodyText: `Last Words; January 11, 2012 <br> Silver Gelatin Photogram <br Lambda C-type Print <br> 8x10” <br> 1/1 (1st edition - 10/10 available) <br> $500USD`,
        imageURL:`/paintings/W03P03.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },

    {
        paintingId: 'W04P01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>"289"</h5><h6>Adolfo Bimer, 2017</h6>',
        emailSubjectText: `"289" Adolfo Bimer, 2017`,
        bodyText: `59” x 35” x 4” Impresión digital sobre papel, pinzas y roscas metálicas <br> ED 3+AP (disponibles 2/3 3/3 AP) <br> $2000 USD`,
        imageURL:`/paintings/W04P01.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        isInSitu: 'Y',
        images: ['W04P01C1.jpg', 'W04P01C2.jpg'],
        annotationScale: {x:.05,y:.05,z:0.05},
        /*  annotationPos:{x:5.6,y:1.8,z:12.086},
          annotationRotation: {x:0,y:0,z:0},*/
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
        annotationPos:{x:-2.67,y:1.2,z:-2.84},
        annotationRotation: {x:0,y:0,z:0},
    },

    {
        paintingId: 'F01S01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Todo Bajo Control</h5><h6>Lucia Madriz, 2007</h6>',
        emailSubjectText: `Todo Bajo Control Lucia Madriz, 2007`,
        bodyText: `48” x 48” Instalación hecha con arroz y frijoles. <br> $4000 USD`,
        imageURL:`/paintings/F01S01.jpg`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: `https://issuu.com/mixedrealitiesltd/docs/km.02_primaveraviral_workslist?fr=sZWFiODE2MjI3MzU`,
        isInSitu: 'Y',
        images: ['F01S01C1.jpg'],
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        annotationScale: {x:.05,y:.05,z:0.05},
        annotationPos:{x:-.02,y:-.36,z:-2.4},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },
];

export {AnnotationsDB, ANNOTATION_CONTACT_TEXT};
