import {AnnotationsDB, ANNOTATION_CONTACT_TEXT} from './AnnotationDB';
import {ANNOTATION_VIDEO_DISPLAY, ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';
import * as THREE from 'three';

class AnnotationDataController {

    static cached_AnnotationDataById = {};

    constructor() {
    }

    static getAnnotationsWithNoParent() {
        const noParentRecords = [];
        const records = AnnotationsDB.filter(rec => rec.annotationParent === ANNOTATION_PARENT.PARENT_NONE);
        if (records) {
            records.forEach(item => {
                noParentRecords.push(AnnotationDataController.populateRecord(item));
            })
        }

        return noParentRecords;
    }

    //todo: need to cache these records in object
    static getAnnotationDataById(annotationId) {

        let records = AnnotationsDB.filter(rec => rec.paintingId === annotationId)
        if (records && records.length > 0) {
            const rec = AnnotationDataController.populateRecord(records[0]);
            if (annotationId in AnnotationDataController.cached_AnnotationDataById) {
                return  AnnotationDataController.cached_AnnotationDataById[annotationId];
            }
            else {
                AnnotationDataController.cached_AnnotationDataById[annotationId] = rec;
                return rec;
            }
        }

        return null;
    }

    static populateRecord(record) {

        if (record) {

            //For now we are not using the "isSold" for almost anything!
            const isSold = record.isSold || 'N';
            const isSendTo = record.isSendTo || 'N';
            const hasChildren = record.hasChildren || 'N';
            let videoURL = null;
            let isAnnotationVideo = false;
            let isStandaloneVideo = false;
            let isAnnotation = false;
            let displayType = record.displayType || ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT;
            isAnnotationVideo = (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO);
            isAnnotation = (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT) || (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO);
            isStandaloneVideo = (displayType & ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE);
            const annotationScale = record.annotationScale ? new THREE.Vector3(record.annotationScale.x, record.annotationScale.y, record.annotationScale.z) : null;
            const annotationPos = record.annotationPos ? new THREE.Vector3(record.annotationPos.x, record.annotationPos.y, record.annotationPos.z) : null;
            const annotationRotation = record.annotationRotation ? new THREE.Vector3(record.annotationRotation.x, record.annotationRotation.y, record.annotationRotation.z) : null;
            const imageURL = record.imageURL || `/paintings/${record.paintingId}.jpg`;
            const iframeSource = record.iframeSource || null;
            const hasCarouselAsParent = record.hasCarouselAsParent || false;

            let ContactURL = null;
            let EnquireEmail = null;
            let shareEmail = null;
            let emailSubjectText = record.emailSubjectText || record.headerText;

            if(record.ContactURL) {
                ContactURL = record.ContactURL;
            }

            //"ENQUIRE" email link
            if(record.EnquireEmail) {
                if(ContactURL) {
                    EnquireEmail = `mailto:${record.EnquireEmail}?subject=${emailSubjectText}`;
                } else {
                    EnquireEmail = `mailto:${record.EnquireEmail}?subject=${emailSubjectText}`;
                }
            }

            //"SEND TO" email link:
            if(isSendTo) {
                if(ContactURL) {
                    shareEmail = `mailto:?subject= ${emailSubjectText} &body=${emailSubjectText}` + escape("\n") + encodeURIComponent(record.ContactURL);
                } else {
                    shareEmail = `mailto:?subject= ${emailSubjectText} &body=${emailSubjectText}`;
                }

            }

            // console.log("[AnnotationDataControl], hasChildren", record.paintingId, hasChildren);

            let childPaintings = [];
            if(hasChildren === 'Y') {
                //Create an array of objects of the child paintingId we have from record.childPaintingId

                if(record.childPaintingId) {
                    record.childPaintingId.map((cPantingId) => {
                        childPaintings.push(AnnotationDataController.getAnnotationDataById(cPantingId));
                    })

                }
            }
            let carouselSiblings = [];
            if(hasCarouselAsParent === 'Y') {
                //Create an array of objects of the child paintingId we have from record.childPaintingId

                // console.log("[AnnotationDataControl], hasCarouselAsParent", record.paintingId, hasCarouselAsParent);

                if(record.carouselSiblings) {

                    record.carouselSiblings.map((cPantingId) => {
                        carouselSiblings.push(AnnotationDataController.getAnnotationDataById(cPantingId));
                    })
                }
            }

            return ({
                moreText: record.moreText,
                headerText: record.headerText,
                bodyText: record.bodyText,
                paintingId: record.paintingId,
                imageUrl: process.env.PUBLIC_URL + imageURL,
                ContactURL: ContactURL,
                isAnnotationVideo: isAnnotationVideo,
                isAnnotation: isAnnotation,
                isStandaloneVideo: isStandaloneVideo,
                displayMesh: record.displayMesh || 'Y',
                displayHeight: record.displayHeight || 100,
                videoURL: process.env.PUBLIC_URL + `/videos/${record.videoURL}`,
                EnquireEmail:EnquireEmail,
                shareEmail:shareEmail,
                annotationScale: annotationScale,
                annotationPos: annotationPos,
                annotationParent: record.annotationParent || ANNOTATION_PARENT.PARENT_MESH,
                isSold: isSold,
                socialMedia: record.socialMedia || 'N',
                spriteImageURL: record.spriteImageURL,
                isInSitu: record.isInSitu || 'N',
                images: record.images || [],
                artistInspirationUrl: record.artistInspirationUrl,
                emailSubjectText: emailSubjectText,
                hasChildren: hasChildren,
                childPaintings: childPaintings,
                iframeSource: iframeSource,
                standaloneVideoElementId:record.standaloneVideoElementId || 'displayVideo',
                hasCarouselAsParent: hasCarouselAsParent,
                carouselSiblings: carouselSiblings,
            });
        }

        return null;
    }
}

export default AnnotationDataController;
