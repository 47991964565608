import React, {Component} from 'react';
import ContactLink from "./ContactLink";

import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

import ReactGa from 'react-ga';

class AnnotationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isShowInSitu: false,
        }

        this.showInSituDialogueHandler = this.showInSituDialogueHandler.bind(this);
        this.inSituCloseBtnHandler = this.inSituCloseBtnHandler.bind(this);
    }

    onShow = () => {
        this.setState({
            ...this.props.annotationData
        })

        //This is part of the Google Analytics Module
        // console.log("ReactGa", this.props.annotationData.paintingId);
        ReactGa.event({
            category: 'Annotation',
            action: `User clicked an annotation ${this.props.annotationData.paintingId}`,
        })
    }

    onSendEmail = () => {
        window.location = this.state.EnquireEmail;
    }

    gotoPage = (urlLink) => {
        window.open(urlLink, '_blank');
    }

    shareEmail = () => {
        window.location = this.state.shareEmail;

    }

    showInSituDialogueHandler = () => {
        this.setState((state) => ({isShowInSitu: true}))
    }

    inSituCloseBtnHandler = () => {
        this.setState({isShowInSitu: false});
    }

    render() {
        /* isVerticalBar expects a boolean value, to check whether it is needed to populate the verticalbar (pipe) or not  */
        const enquire = <ContactLink contactName="ENQUIRE" isVerticalBar={false} hrefLink={this.state.EnquireEmail} onClickHandler={this.onSendEmail} />;
        const questions = <ContactLink contactName="QUESTIONS" isVerticalBar={true} hrefLink={this.state.EnquireEmail} onClickHandler={this.onSendEmail} />;
        const exhibitionPdfLink = <ContactLink contactName="WORKS PDF" isVerticalBar={true} hrefLink={this.state.ContactURL} onClickHandler={() => this.gotoPage(this.props.annotationData.ContactURL)}/>;


        let inSituLink = null;
        if (this.state.isInSitu === "Y") {
            inSituLink = <ContactLink contactName="DETAIL" isVerticalBar={false} hrefLink="#" onClickHandler={this.showInSituDialogueHandler}/>
        }

        // let artistInspirationUrl = null;
        // artistInspirationUrl = <ContactLink contactName="ARTIST INSPIRATION" isVerticalBar={inSituLink} hrefLink={this.state.artistInspirationUrl} onClickHandler={() => this.gotoPage(this.props.annotationData.artistInspirationUrl)}/>

        let displayCarouselItems = null;
        if (this.props.annotationData && this.props.annotationData.images.length > 0) {
            displayCarouselItems = this.props.annotationData.images.map((image, index) => {
                return (
                    <Carousel.Item key={image}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + '/paintings/' + image}
                            alt="First slide"
                        />
                    </Carousel.Item>
                )
            })
        }


        if (this.state.isShowInSitu) {
            /* Show the In Situ Page*/
            // console.log("[AnnotationModal] images length", this.props.annotationData.images.length);
            return (
                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.state.isShowInSitu} /*onShow={() => this.onShow()} */
                        onHide={this.inSituCloseBtnHandler} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>

                            <Carousel>
                                {displayCarouselItems}
                            </Carousel>


                        </Modal.Body>
                    </Modal>
                </div>
            )

        } else {

            return (

                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.props.showAnnotationModal} onShow={() => this.onShow()}
                        onHide={this.props.hideAnnotationModal} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="annotation-main-container-modal">
                                    <div className="annotation-media-container">
                                        <div className="annotation-image-wrapper">
                                            {this.state.isAnnotationVideo ?
                                                <video width="100%" controls>
                                                    <source src={this.state.videoURL} type="video/mp4"/>
                                                    <source src={this.state.videoURL} type="video/ogg"/>
                                                    Your browser does not support HTML video.
                                                </video> :
                                                <img
                                                    src={this.state.imageUrl} alt="Annotation painting section"/>
                                            }
                                        </div>
                                    </div>
                                    <div className="annotation-text-container">
                                        <div className="annotation-header-text-container"
                                             dangerouslySetInnerHTML={{__html: this.state.headerText}}>
                                        </div>
                                        <div className="annotation-body-text-container">
                                            <p dangerouslySetInnerHTML={{__html: this.state.bodyText}}></p>
                                        </div>
                                    </div>
                                    <div className="annotation-contact-container">
                                        <h6>
                                            {enquire} {questions} {exhibitionPdfLink}
                                        </h6>
                                        <h6>
                                            {inSituLink}
                                        </h6>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                /* */
            )

        }


    }


}

export default AnnotationModal;
